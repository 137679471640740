import axios from "axios";

export const sfAxios = axios.create({
  baseURL: "https://api.swissforecast.ch",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    withCredentials: false,
  },
});

export function setAxiosDefaults() {
  const token = localStorage.getItem("accessToken");
  const sfToken = localStorage.getItem("sfToken");

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  if (sfToken) {
    sfAxios.defaults.headers.common["Authorization"] = `Bearer ${sfToken}`;
  }
}

export default function http(method, url, data, config) {
  const request = { method, url, data, ...config };

  return new Promise(async (resolve, reject) => {
    await axios(request)
      .then((res) => resolve(res))
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
}
