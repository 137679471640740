import { CircularProgress, Select, MenuItem, FormControl, InputLabel, Grid, Autocomplete, Stack, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'

const ShowCaseMain = styled("div")(({ theme }) => ({
    position: "relative",
    padding: "0",
    top: 10,
  }));


const DropDownDiv = styled("div")(({ theme }) => ({
    paddingTop: "20",
    width: 1200
}));

function ShowCase() {

  //const base_url = "http://127.0.0.1:5500/"
  const base_url = "https://api.swissforecast.ch/"
  const product_endpoint = "data/n7dDA28QD1FrvCre4kCX?verification=fOyGkSO7eDszQJNvCFZ0"
  //const data_endpoint = "data/YfBg7bf2O1UdeAKD6khE?product=B&verification=H3UsHGIbdqq26mjoQbaw"

  const [isLoading, setIsLoading] = useState(true);
  const [isSetup, setIsSetup] = useState(true);
  const [seriesData, setSeriesData] = useState([]);
  const [filteredSeriesData, setFilteredSeriesData] = useState([]);
  const [filteredDataChartOptions, setFilteredDataChartOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [currentSeriesSelection, setCurrentSeriesSelection] = useState({});
  const [countries, setCountries] = useState([]);
  const [currentCountrySelection, setCurrentCountrySelection] = useState("DE");
  const [countryChange, setCountryChange] = useState(false)

  /*
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  */

  const fetchData = async (p) => {   
    setIsLoading(true)
    setSeriesData(await fetch(build_data_endpoint_url(p)).then(response => response.json()));
  }

  const check_country_change_date = async () => {
    var y_p = [];
    if(filteredSeriesData.length > 0) {
      y_p = [filteredSeriesData[0].year, filteredSeriesData[0].month]
    } else {
      y_p = [2005, 1]
    }
    await setFilteredDataChartOptions({
      data: filteredSeriesData.map(row => row.value),
      year: y_p[0],
      month: y_p[1]      
    })
  }

  useEffect(() => {


    // ## SETUP

    // ##### Get Series
    const fetchSeries = async () => {
      setSeries(await fetch(base_url + product_endpoint).then(response => response.json()));
    }

    if (isSetup && series.length > 0) {
      setCurrentSeriesSelection(series.find(r => r.nace_r2.trim() === "C236"));
    } 
    
    if (isSetup && series.length === 0) {      
      fetchSeries().catch(console.error);
    }


    // ##### Get Series Data
    if(seriesData.length === 0 && series.length > 0) {
        fetchData("C236").catch(console.error);  
        setIsSetup(false)
    }     
    //if(seriesData.length > 0 && isSetup) {
      //setIsSetup(false)
    //}

    // SETUP ##

    // ## Runtime
    // console.log(seriesData.length, filteredSeriesData.length, isLoading, isSetup);
    
    if(filteredSeriesData.length === 0 && isLoading && !isSetup){
      check_ctr()
      filter_data();
      setIsLoading(false)
    }

    if(countryChange) {
      check_country_change_date();
      setCountryChange(false);
    }
    // Runtime ##   
    
  }, [series, seriesData, currentCountrySelection, countryChange]);

  function build_data_endpoint_url(p) {
    return base_url + "data/YfBg7bf2O1UdeAKD6khE?product=" + p + "&verification=H3UsHGIbdqq26mjoQbaw"
  }

  function check_ctr() {
      var c = [...new Set(seriesData.map(item => item.geo))];
      setCountries(c);    
      
      // Backup falls ausgewähltes Land in neuem Datenset nicht verfügbar ist
      if(!currentCountrySelection && "DE" in countries){
        setCurrentCountrySelection("DE")
      } else if (!currentCountrySelection && "DE" in countries === false){
        setCurrentCountrySelection(countries[0])
      }
    }

  const autoCompleteHandleSeriesChange = (event) => {
    // Holt nace_r2 code abhängig von der ausgewählten description
    // --> da in autocomplete <li> als children muss hier outerText genutzt werden. Siehe console.log(event.target)
    var keyFromDescription = series.find(r => r.description.trim().toLowerCase() + " (" + r.nace_r2.trim().toLowerCase() + ")" === event.target.outerText.trim().toLowerCase());
    setCurrentSeriesSelection({nace_r2: keyFromDescription.nace_r2, description: keyFromDescription.description});  
    // Holt neues dataset aus der DB -- Triggert useEffect
    fetchData(keyFromDescription.nace_r2);
    setFilteredSeriesData([])
    setIsLoading(true)    
  }

  const countryHandleChange = (event) => {
    setCurrentCountrySelection(event.target.value);
    setFilteredSeriesData([])
    setIsLoading(true)
    
  };    

  function renderSeriesOptions() { 
    return series.map(item => <MenuItem key={item.nace_r2} value={item.description}>{item.description}</MenuItem> );
  } 

  function renderCountryOptions() {
    return countries.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);
  }

  function filter_data() {
    setFilteredSeriesData(seriesData.filter(item => item.geo === currentCountrySelection));
    setCountryChange(true);
  }

  function get_chart_options() {
    return {
      chart: {
        height: 650,
        width: 1200,
      },
      title: {
        text: 'Price Index - ' + currentSeriesSelection.description + ' - ' + currentCountrySelection
      },
      subtitle: {
        //text: 'Manufacture of ceramic tiles and flags<br />Manufacture of bricks, tiles and construction products, in baked clay<br />Manufacture of cement, lime and plaster'
        text: 'Index 2021=100'
      }, 
      plotOptions: {
        series: {
            cumulative: false,
            pointStart: Date.UTC(filteredDataChartOptions.year, filteredDataChartOptions.month - 1, 1),
            pointIntervalUnit: 'month'
        }
      },  
      rangeSelector: {
        enabled: true,
        buttons: [{
              type: 'month',
              count: 6,
              text: '6m'
              }, 
              {
              type: 'year',
              count: 1,
              text: '1y'
              }, 
              {
                type: 'year',
                count: 5,
                text: '5y'
                },             
              {
              type: 'all',
              text: 'All'
              },],
        inputEnabled: false,
        selected: 4 // all      
      }, 
      series: [
        {
          id: currentSeriesSelection.description,
          name: currentSeriesSelection.description,
          data: filteredDataChartOptions.data
        }  
      ]
    }
  }

  return (    
    <ShowCaseMain>
      {isLoading ? <CircularProgress /> : <>
        <DropDownDiv>
            <Grid container spacing={3}>
            { series && currentSeriesSelection ?
                  <Grid item sm={12} md={6}>
                  <Stack sx={{ width: "100%", margin: "auto"}}>
                    <Autocomplete 
                      options={series}
                      getOptionLabel={(seriesOption) => seriesOption.description + " (" + seriesOption.nace_r2 + ")"}
                      renderOption={(props, series) => (
                        <MenuItem component="li" {...props} key={series.nace_r2}>
                          {series.description} ({series.nace_r2})
                        </MenuItem>
                      )}
                      renderInput={(params) => <TextField {...params} label={currentSeriesSelection.description + " (" + currentSeriesSelection.nace_r2 + ")"} />}
                      onChange={(event) => autoCompleteHandleSeriesChange(event)}
                      isOptionEqualToValue={(option, value) => 
                        option.description === value.description
                      }

                    />
                  </Stack>
                </Grid>

                :
                <TextField value="Loading..." label="Loading..." />
                }        
              <Grid item sm={12} md={6}>
              <FormControl fullWidth>
              <InputLabel id="country">Country</InputLabel>
              { countries ? 
                <Select
                  labelId="country-select"
                  id="country-select"
                  value={currentCountrySelection}
                  label={currentCountrySelection}
                  onChange={countryHandleChange}
                >
                  {renderCountryOptions()}
                </Select>
                :
                <Select labelId="country-select" id="country-select" value="Country" label="Country"></Select>
              }
              </FormControl>            
              </Grid>                       
            </Grid>
        </DropDownDiv>                   
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={get_chart_options()}
            /> 
      </>}

    </ShowCaseMain>
  )}

export default ShowCase;