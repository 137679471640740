import axios from "axios";
import AppRoutes from "core/routes";
import { useEffect } from "react";
import { setAxiosDefaults } from "utils/http";

function App() {

  useEffect(() => {
    setAxiosDefaults();
  }, []);

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
