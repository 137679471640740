const en = {
  header: {
    background: "Hintergrund",
    method: "Methode",
    whySwissforecast: "Warum Swissforecast?",
    services: "Services",
    signIn: "Anmelden",
    becomeAMember: "Werde Mitglied",
    dashboard: "Dashboard",
    logout: "Logout",
    blog: "Blog",
  },
  footer: {},
  login: {
    login: "Login",
  },
  home: {
    home: "Home",
    priceForecastFor: "Price Forecast for",
    industrialGoods: "Industrial Goods Worldwide",
    startFreeTrial: "Start free trial",
    learnMore: "Learn more",
    background: "Background",
    backgroundInfo: `Die Preisentwicklung von z. B. von Flachglas setzt sich zusammen
    aus dem Materialeinsatz (Sand und Gas), den Lohnkosten, den
    Betriebskosten und dem EBITDA. Die Swissforecast bewertet den pro
    Industriegut den jeweiligen Einfluss, prognostiziert die Faktoren
    (Sand, Gas, Löhne etc.) getrennt und leitet die Preisentwicklung
    es Industriegutes ab. In der ex-post Betrachtung werden der
    zeitliche Versatz der Preisänderungseinflüsse erhoben und in das
    Model eingefügt. Die Modelle wurden in standardisierten A/B Tests
    an 20 bis 40-Jährigen Zeitreihen validiert.`,
    method: `Methode der wertschöpfungsbezogenen Preisprognose`,
    methodInfo: `Die Preisentwicklung von z. B. von Flachglas setzt sich zusammen
    aus dem Materialeinsatz (Sand und Gas), den Lohnkosten, den
    Betriebskosten und dem EBITDA. Die Swissforecast bewertet den pro
    Industriegut den jeweiligen Einfluss, prognostiziert die Faktoren
    (Sand, Gas, Löhne etc.) getrennt und leitet die Preisentwicklung
    es Industriegutes ab. In der ex-post Betrachtung werden der
    zeitliche Versatz der Preisänderungseinflüsse erhoben und in das
    Model eingefügt. Die Modelle wurden in standardisierten A/B Tests
    an 20 bis 40-Jährigen Zeitreihen validiert.`,
    whySwissforecast: `Warum Swissforecast?`,
    whySwissforecastInfo: `Die Entwickler der Swissforecast‘s sind seit 25 Jahren in der
    Marktforschung für Industriegüter und liefern Daten für die
    Controlling-, BI-, Finanz- und Planungsverantwortlichen. Auch
    die Marktmodelle in der Due Diligence benötigen
    Price-Forecasts der zum Einsatz und ergänzen die Management
    Estimations. Dieses Daten liefern wir und bauen ein weltweites
    Datenset auf. Die Prognosen stehen in Pivot-Tabellen und als
    Datendownload jederzeit im Datenportal zur Verfügung. Alerts
    machen auf Updates aufmerksam und liefern diese regelmäßig per
    Schnittstelle oder E-Mail.`,
    whereUsed: `Wo kommen die Swissforecast‘s zum Einsatz?`,
    whereUsedList: {
      1: `Erfassung aller offiziellen Erzeugerpreis-Indizies weltweit`,
      2: `Eigene Erhebung fehlender Erzeugerpreise`,
      3: `Analyse der Wertschöpfungskette für Industriegüter`,
      4: `Angepasste Prognosemodelle entlang der Wertschöpfungskette`,
    },
  },
};

export default en;
