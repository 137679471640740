import i18n from "i18next";
import de from "locale/de";
import en from "locale/en";
import { initReactI18next } from "react-i18next";

const config = {
  lng: "de",
  fallbackLng: "en",
  // debug: true,
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    bindI18n: "languageChanged",
    bindI18nStore: "",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    useSuspense: true,
  },
};

i18n.use(initReactI18next).init(config);

export default i18n;
