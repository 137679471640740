import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#262626",
    },
    secondary: {
      main: "#7B7B7B",
    },
  },
  typography: {
    fontFamily: ["Helvetica Neue LT Std, sans-serif"],
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 30,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Helvetica Neue LT Std, sans-serif",
          textTransform: "capitalize",
          fontSize: 14,
          lineHeight: "20px",
        },
      },
    },
  },
});
export default theme;
